import React from "react";
import { Row } from "antd";
import jetpackLogo from "../../assets/jetpackfulllogo.png";

export const Footer = ({ children }) => <div style={{ justifySelf: "end", marginTop: "auto" }}>{children}</div>;

const JetpackFooter = () => {
  return (
    <Row justify="center">
      <a href="https://jetpack.ai" target="_blank" rel="noopener noreferrer" style={{ color: "black" }}>
        <small>
          Made with ♥ by <img alt="jetpackAI" src={jetpackLogo} style={{ height: 16, display: "inline" }} />
        </small>
      </a>
    </Row>
  );
};

export default JetpackFooter;
