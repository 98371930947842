//supprimer selon pertinence
import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";
import { useLocation } from "../../../components/Utils/Location";
export default function Content() {
  //@ts-ignore
  const [event, setEvent] = useContext(EventContext);
  const [location, setLocation] = useLocation();

  return (
    <div>
      <h4>Aléa</h4>
      <p>
        Les changements climatiques entraîneront une plue grande irrégularité des précipitations que ce que nous avons connu jusqu’à présent. Dans les
        scénarios de projection d'un monde à +3° et +4° les inondations se font moins fréquentes et ce sont les sécheresses qui domineront en
        Wallonie.
      </p>
      <h4>Facteurs aggravants</h4>
      <p>
        Ces sécheresses météorologique sont aggravées par l’imperméabilisation des sols, conséquence directe de l’augmentation de l’artificialisation
        du territoire, qui rendront les sécheresses édaphiques et hydrologiques encore plus fréquentes. Au plus une surface est imperméabilisé, au
        plus l’eau ruissellera sur cette surface au lieu d'être disponible pour les plantes dans la couche superficielle du sol ou s’infiltrer et
        rejoindre les nappes phréatiques.
      </p>
      <h4>Exposition</h4>
      <p>
        L'étude prospective sur le risque de raréfaction de la ressource en eau en Wallonie sous l'effet du changement climatique{" "}
        <a href="https://www.iweps.be/wp-content/uploads/2020/09/CAPRO-4-secheresse-final2.pdf">^[DEMNA-IWEPS, 2020]</a>, montre qu'entre 2017 et 2019
        un quart des communes wallonnes a connu des périodes de pénurie d'eau de distribution et pour plus d'une vingtaine de communes cela s'est
        reproduit les trois années d'affilée. Les différents types de sécheresses vont engendrer des risques pour les cultures, des pénuries d'eau
        douce disponible pour répondre à une demande en augmentation dans différents secteurs (consommation humaine, refroidissement industriel,
        irrigation, tourisme, …). Plusieurs dispositifs sont mis en place en Wallonie comme la cellule « Sécheresse » pilotée par le Centre régional
        de crise en Wallonie qui propose des mesures de gestion de l’eau, le « Dispositif sécheresse pour la Wallonie » qui vise à protéger les
        ressources hydriques et limiter les usages en eau ou encore le Schéma Régional des ressources en eau 2.0 lancé en 2020 qui vise à mieux
        prendre en compte l’évolution du climat au niveau de la gestion de l’offre et de la demande.
      </p>
      <h4>Vulnérabilité</h4>
      <p>
        La vulnérabilité des utilisateurs qui entreront en conflit autour de la ressource eau dépendra principalement des accès qu'ils auront à des
        sources alternative d'eau.
      </p>
      <h4>Impacts</h4>
      <p>
        Évaluer l’impact économique au niveau de la population n’est pas simple. L’eau est un besoin vital. Cependant la quantité d’eau nécessaire
        pour remplir ce besoin vital ne représente que 7% de la consommation actuelles moyenne de l'eau de distribution. Cela s’explique par les
        autres usages tels que l’hygiène domestique, qui sont importants, mais qui pourraient être assurés par une eau non potable.
      </p>
      <p>
        La ressource eau représente également un coût économique de distribution. Afin d’assurer la distribution en eau et d’éviter les pertes sur le
        réseau, la Wallonie investit en moyenne 117 M€/an pour son infrastructure (2015-2019). Ce sont des investissements nécessaires et sans doute
        encore insuffisants lorsque l’on compare ce coût d’entretien aux pertes économiques liées aux fuites sur le réseau. Celles-ci sont de l’ordre
        de 358 M€/an [source : statistiques Aquawal 2020]. Par ailleurs l’étude d’évaluation de l’impact socio-économique du changement climatique en
        Belgique{" "}
        <a href="https://www.adapt2climate.be/etude-evaluation-des-impacts-socio-economiques-des-changements-climatiques-en-belgique/">
          ^[CNC, 2020]
        </a>{" "}
        suggère une estimation de coût pour un scénario où les pénuries plus fréquentes amèneraient à des rationnements de 25% de l’usage de l’eau
        pendant une période de 20 jours par an. Le coût d’un tel scénario pour Wallonie représenterait 500 000 000 d’euros. Il ne s’agit toutefois que
        d’un scénario fictif dont il n’est pas possible d’estimer la probabilité annuelle selon les différents scénarios climatiques et aux horizons
        2050 et 2100
      </p>
      <h4>Ce que montrent les cartes</h4>
      <p>
        Sur ces cartes vous pouvez explorer la vulnérabilité des sols et des masses d'eau en Wallonie face aux sécheresses. Les scénarios futurs de
        changements climatiques et d'occupation du sol peuvent être sélectionnés en haut de la carte.
      </p>
      <p>
        <b>Les sécheresses édaphiques.</b>
        C'est à dire le risque que les sols ne contiennent plus suffisamment d'eau pour alimenter la végétation qu'ils accueillent. La partie gauche
        de la carte montre la teneur en eau actuelle des sols wallons. La partie droite représente le taux de perte future estimé. Les zones les plus
        à risque de sécheresses édaphiques sont représentées en rouge.
      </p>
      <p>
        <b>Nappes d’eau à risques de sécheresse hydrologique</b>
        C'est à dire le risque que la nappe phréatique ne soit plus suffisamment rechargée et que son volume diminue. Cela peut mener à un volume
        insuffisant que pour pouvoir y prélever de l'eau pour nos besoins. La partie gauche de la carte montre les masses d'eau souterraines. La
        partie droite représente le taux de perte future estimé. Les nappes les plus à risque de sécheresses hydrologique sont représentées en rouge.
      </p>
      <p>
        <b>Cours d’eau à risques d’étiage</b>
        C'est à dire le risque que le débit du cours d'eau soit très faible. Cela peut impacter les usages du cours d'eau (navigation, pompage pour le
        refroidissement des centrales, production hydroélectrique, ...) et mettre en danger la biodiversité autour du cours d'eau. La partie gauche de
        la carte montre le réseau hydrographique (les cours d'eau) de Wallonie. La partie droite représente l'aggravation future estimée du risque
        d'étiage. Les cours d'eau les plus à risque sont représentés en rouge.
      </p>
      <p>
        <b>Zones de conflits d'approvisionnement en eau</b>
        C'est à dire le risque combiné dans une zone de la diminution de la disponibilité de la ressource eau et/ou de l'augmentation de la demande
        (eau de distribution, refroidissement, hydroélectricité, irrigation, ...). Cela peut générer des conflits pour l'usage de l'eau et des besoins
        d'arbitrage de priorité. La partie gauche de la carte montre les zones de distribution en eau. La partie droite représente le risque de
        conflit futur estimé. Les zones de distribution les plus à risque sont représentées en rouge.
      </p>
      <h4>Rapport méthodologique complet</h4>
      <p>
        <a href="">[Pirlot, C et al. 2024]</a>
      </p>
    </div>
  );
}
