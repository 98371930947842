import React from "react";
import { Row, Col, Divider, List, Avatar } from "antd";
import { useThemes } from "../../hooks/staticThemes";
import { useTheme } from "../../hooks/theme";
import _ from "lodash";
import ImpactGraph from "../Charts/ImpactGraph/ImpactGraphComponent";

export const ThemeButton: React.FC<{
  id: string;
  label: string;
  icon: string;
  selected?: boolean;
}> = ({ id, label, icon, selected }) => {
  const [, setTheme] = useTheme();

  return (
    <Col
      span={4}
      style={{
        backgroundColor: selected ? "#E5E5E5" : "transparent",
        borderRadius: 8,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <button
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: 8,
          gap: 5,
          border: "none",
          backgroundColor: "transparent",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => {
          setTheme(id);
        }}
      >
        {/* style={{ filter: selected ? "" : "grayscale(100%)" }} */}
        <img src={icon} width={70} alt={id} />
        <strong>{label}</strong>
      </button>
    </Col>
  );
};

const ThemePicker = () => {
  const [selectedTheme] = useTheme();
  const { data: themes = [] } = useThemes();
  const [, setTheme] = useTheme();
  return (
    <div style={{ overflowY: "scroll", height: "calc(100% - 24px)" }}>
      <Row style={{ padding: 16, marginTop: 16, borderRadius: 42, fontSize: "2em", fontWeight: 500 }}>
        <div>Indicateurs de Vulnérabilité</div>
      </Row>
      <Divider />
      <ImpactGraph themes={themes} />

      <div style={{ padding: 16 }}>
        <List
          itemLayout="horizontal"
          dataSource={_(themes).orderBy("cluster").value()}
          renderItem={(theme) => (
            <List.Item onClick={() => setTheme(theme.id)} style={{ cursor: "pointer" }}>
              <List.Item.Meta avatar={<Avatar size={64} shape="square" src={theme.icon} />} title={theme.label} description={theme.clusterLabel} />
            </List.Item>
          )}
        />
      </div>
      {/* {themes &&
        _(themes)
          .groupBy("cluster")
          .map((g) => (
            <div>
              <Divider orientation="left">{g[0].clusterLabel}</Divider>
              <Row gutter={8} style={{ padding: 8 }}>
                {g.map((theme) => {
                  return <ThemeButton key={theme.id} id={theme.id} label={theme.label} icon={theme.icon} selected={theme.id === selectedTheme?.id} />;
                })}
              </Row>
            </div>
          ))
          .value()} */}
    </div>
  );
};

export default ThemePicker;
