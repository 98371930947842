//supprimer selon pertinence
import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";
import { useLocation } from "../../../components/Utils/Location";
export default function Content() {
  //@ts-ignore
  const [event, setEvent] = useContext(EventContext);
  const [location, setLocation] = useLocation();

  return (
    <div>
      <h4>Aléa</h4>
      <p>
        Le dérèglement climatique entrainera une augmentation de la fréquence des évènements météorologiques extrêmes. Les plus impactant pour les
        cultures sont les épisodes de sécheresse dont la fréquence devrait doubler. Les conditions plus précoces de croissance vont également
        augmenter le risque de gelées tardives de 20 à 120%{" "}
        <a href="https://www.worldweatherattribution.org/human-caused-climate-change-increased-the-likelihood-of-early-growing-period-frost-in-france">
          (World Weather Attribution)
        </a>
        .
      </p>
      <h4>Facteurs aggravants</h4>
      <p>
        Le manque d’eau et l’augmentation des conflits d’usage de cette ressource vont limiter les possibilités d’irrigation supplémentaire des terres
        durant les sècheresses, aggravant l’état du bilan hydrique. En parallèle la tendance continue à l’imperméabilisation des terres augmente les
        risques de ruissellement de surface plutôt que d’infiltration de l’eau dans les sols. Ceci accentue encore le déficit d’eau. De plus le
        ruissellement contribue à l’érosion des sols agricoles, diminuant ainsi leur fertilité.
      </p>
      <h4>Exposition</h4>
      <p>
        Les parcelles agricoles, dans leurs positions géographiques, leur appartenance à une région agricole et donc leurs caractéristiques
        pédoclimatiques sont plus ou moins exposées à ces calamités. Les régions limoneuses, du Condroz et de la campine hennuyère sont orientées
        majoritairement vers les filières des légumes, de céréales, de la pomme de terre et de la betterave sucrière. Elles sont particulièrement
        sensible au risque de sécheresse ou d'inondation. Les régions herbagère et de la Haute Ardenne sont spécialisées dans la filière du lait. Les
        régions de l’Ardenne, la Famenne et des Fagnes sont dominées par la filière de la viande bovine. Les prairies nécessaires pour ces filières
        sont particulièrement sensibles aux périodes de sécheresses prolongées.
      </p>
      <h4>Vulnérabilité</h4>
      <p>
        Certaines pratiques agricoles rendent les cultures plus ou moins résilientes au dérèglement climatique et à ses conséquences météorologiques.
        La monoculture réduit la complexité du système racinaire, rendant le sol plus sensible à l’érosion et diminuant la capacité à puiser l’eau à
        divers niveaux. Les cultures sont ainsi d’autant plus exposées aux risques de manque d’eau. Les monocultures sont également plus sensibles aux
        risques de maladies et ravageurs. Enfin, une exploitation basée sur une seule culture sera économiquement très sensible à toute baisse de
        rendement ou dégât sur cette culture.
      </p>
      <p>
        Un travail mécanique important du sol avec des engins lourd, implique également un tassement et une déstructuration du sol qui diminue sa
        fertilité et sa capacité de stockage d’eau.
      </p>
      <h4>Ce que montrent les cartes</h4>
      <p>
        Sur cette carte vous pouvez explorer la vulnérabilité des cultures Wallonnes. Les scénarios futurs de changements climatiques peuvent être
        sélectionnés en haut de la carte. La partie gauche de la carte montre le potentiel de rendement actuel du parcellaire agricole. La partie
        droite représente le taux de perte future estimé. Les zones les plus à risque de perte de rendement en Wallonie sont représentées en rouge.
      </p>
      <h4>Rapport méthodologique complet</h4>
      <p>
        <a href="">[Bindelle, J. et al. 2024]</a>
      </p>
    </div>
  );
}
