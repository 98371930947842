import React from "react";
import ThemePicker from "../../Utils/ThemePicker";
import { useTheme } from "../../../hooks/theme";
import JetpackFooter, { Footer } from "../../UI/JetpackFooter";
import { FloatButton, Tabs } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useSize } from "../../../hooks/size";
import { useSidePanelStore } from "../../../hooks/sidePanel";
import SidePanelHeader from "./SidePanelHeader";
import SidePanelImpact from "./SidePanelImpact";
import SidePanelCommune from "./SidePanelCommune";
import MapMenu from "../../Utils/MapMenu";

export const PANEL_WIDTH = 700;
/** DEPRECATED */
export const isMobileMode = () => {
  //console.warn("Support of mobile mode is deprecated");
  return false;
};

const SidePanel = () => {
  useSize();
  const { folded, setFolded } = useSidePanelStore();
  const [theme] = useTheme();

  const tabs = [
    {
      key: "impact",
      label: "Impact",
      children: <SidePanelImpact />,
    },
    {
      key: "maps",
      label: "Cartes",
      children: <MapMenu />,
    },
    // {
    //   key: "commune",
    //   label: "Commune",
    //   children: <SidePanelCommune />,
    // },
  ];

  return (
    <>
      <FloatButton
        style={{
          top: 30,
          left: folded ? 30 : PANEL_WIDTH - 80,
          transition: "left 0.3s",
        }}
        icon={folded ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        onClick={() => {
          setFolded(!folded);
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: PANEL_WIDTH,
          height: "100%",
          padding: 10,
          display: "flex",
          justifyContent: "stretch",
          zIndex: 50,
          transform: `translateX(${folded ? -PANEL_WIDTH : 0}px)`,
          transition: "transform 0.3s",
        }}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            boxShadow: "1px 3px 5px #3F3F3F55",
            padding: "10px",
            borderRadius: 8,
          }}
        >
          {!theme && <ThemePicker />}
          {theme && (
            <div style={{ height: "calc(100% - 24px)", overflowY: "scroll", fontSize: 14 }}>
              <SidePanelHeader />
              <Tabs centered defaultActiveKey="impact" items={tabs} />
            </div>
          )}
          <Footer>
            <JetpackFooter />
          </Footer>
        </div>
      </div>
    </>
  );
};

export default SidePanel;
