//supprimer selon pertinence
import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";
import { useLocation } from "../../../components/Utils/Location";
export default function Content() {
  //@ts-ignore
  const [event, setEvent] = useContext(EventContext);
  const [location, setLocation] = useLocation();

  return (
    <div>
      <h4>Aléa</h4>
      <p>
        Une augmentation de la température de 2°C à l’horizon 2100, prévue en Wallonie selon le scénario RCP 4.5 du GIEC, entraînerait une diminution
        de la production agricole, ce qui poserait un risque pour la sécurité alimentaire si la demande continue à croître. L'augmentation de
        l'évapotranspiration est une cause de la sécheresse agricole, contribuant à la diminution de l'humidité des sols. Les précipitations extrêmes,
        ainsi que le ruissellement, sont responsables de l'érosion des terres agricoles, ce qui conduit à d'autres risques tels que la contamination
        par les intrants chimiques, la dégradation des sols, les coulées de boue et les inondations. À court et moyen terme, le secteur agricole
        pourrait voir ses rendements augmenter dans leur ensemble en raison d’un allongement de la période de croissance végétative, de températures
        plus élevées et d’une plus grande concentration de CO2 dans l’atmosphère.
      </p>
      <h4>Facteurs aggravants</h4>
      <p>
        L'utilisation intensive des sols peut altérer leur texture et leur capacité d'infiltration et de rétention d'eau. La réduction des résidus de
        cultures peut diminuer la quantité de matière organique dans le sol le rendant plus vulnérable à l'érosion. Les cultures sarclées et la
        diminution du couvert végétal augmentent également les risques d'érosion, notamment en favorisant les coulées de boue. L’usage fréquent
        d'engins agricoles entraîne une compaction du sol, le rendant moins perméable à l'eau et favorisant ainsi la sécheresse.
      </p>
      <h4>Exposition</h4>
      <p>
        En Wallonie, les sols agricoles sont particulièrement sujets à l'érosion pour plusieurs raisons :
        <ul>
          <li>
            Les sols de la Région Limoneuse, la Région sablo-limoneuse et le Condroz sont particulièrement concernés en raison des cultures sarclées,
            aggravées par une faible teneur en matière organique.
          </li>
          <li>Les sols agricoles du Condroz sont également vulnérables en raison de leur faible épaisseur et de leur charge caillouteuse. </li>
          <li>Les régions de Bouillon, Attert et Arlon sont exposées à l'érosion en raison de la présence de pentes. </li>
        </ul>
        Les pertes de sol agricole dues à l'érosion, estimées sur la période 2017-2021, ont atteint en moyenne 8,5 tonnes par hectare et par an. En
        2021, une année marquée par des pluies très érosives, ces pertes ont grimpé à près de 15 tonnes par hectare, dépassant ainsi les seuils
        critiques. Les provinces du nord de la Wallonie ont connu une accentuation due à des déficits en eau pendant les saisons de printemps et
        d'été, sur la période de 2000 à 2022. Le lessivage des intrants chimiques et l'érosion des terres agricoles menacent la biodiversité en
        entraînant la pollution et la sédimentation dans les cours d'eau.
      </p>
      <h4>Vulnérabilité</h4>
      <p>
        La texture, la structure et l'épaisseur des sols agricoles influent sur leur résistance ou leur vulnérabilité aux conditions climatiques, en
        particulier en ce qui concerne l'infiltration de l'eau. Le type de couverture végétale et les cultures cultivées affectent également leur
        résistance, notamment face à l'érosion, à la vitesse d'évapotranspiration et à l'exposition aux conditions de sécheresse. La topographie et la
        localisation des terres cultivées, en particulier sur des pentes, les rendent plus vulnérables à l'érosion, surtout lorsque les pentes sont
        raides. La teneur en matière organique, essentielle pour la fertilité des sols, influence également leur structure et présente des effets
        bénéfiques face aux changements climatiques, tels que le stockage du carbone et la dégradation des polluants.
      </p>
      <h4>Impacts</h4>
      <p></p>
      <h4>Ce que montrent les cartes</h4>
      <p>
        La présente carte vous permet de visualiser le potentiel d'érosion des sols en Wallonie en prenant en compte divers facteurs tels que la
        texture du sol, son taux de matière organique, la topographie et inclue la position de la parcelle dans le bassin versant, sa pente, ainsi que
        l'intensité des précipitations dans la région. La seconde carte vous fournit des informations les informations relatives à la cartographie des
        teneurs et des stocks de Carbone Organique Total (COT) des sols agricoles de Wallonie. Le carbone organique est crucial pour la fertilité des
        sols en raison de sa présence dans la matière organique. Ces couches cartographiques vous permettront de visualiser le potentiel des sols
        wallons face à la perte de leurs qualités agronomiques due aux changements climatiques.
      </p>
      <h4>Rapport méthodologique complet</h4>
      <p>
        <a href="">[Pirlot, C. et al. 2024]</a>
      </p>
    </div>
  );
}
