import { useEffect, useRef, useState } from "react";
import * as Plot from "@observablehq/plot";
import _ from "lodash";
import * as d3 from "d3";
import { Select } from "antd";
import { useResizeDetector } from "react-resize-detector";
import numeral from "numeral";
import socialData from "../../assets/data/social.json";

const ScatterPlot = ({ data }) => {
  const ref = useRef();
  const { width: componentWidth, ref: refParent } = useResizeDetector();
  const [chart, setChart] = useState(null);

  const vulnérabilités = ["Très élevée", "Elevée", "Modérée", "Faible", "Très faible"];
  useEffect(() => {
    if (data && componentWidth) {
      const processedData = data.vul.map((d, i) => ({
        rank: i,
        label: vulnérabilités[i],
        count: d,
        proportion: d / data.t,
      }));

      const chart = Plot.plot({
        margin: 30,
        x: {
          axis: "top",
          grid: true,
          percent: true,
          label: "Proportion de la population",
          tickFormat: (d) => `${d}%`,
        },
        color: {
          legend: true,
          domain: vulnérabilités,
          range: ["#ff0000", "#ff8000", "#ffe242", "#8fd30a", "#39aa15"],
        },
        marks: [
          Plot.barX(
            processedData,
            Plot.groupZ(
              { x: "proportion" },
              {
                x: "proportion",
                fill: "label",
                tip: true,
                title: (d) => `${d.label} ${Math.round(d.proportion * 1000) / 10}% (${d.count} personnes)`,
              },
            ),
          ),
          Plot.textX(
            processedData,
            Plot.groupZ({ x: "proportion" }, { x: "proportion", fill: "black", text: (d) => Math.round(100 * d.proportion) }),
          ),
          //Plot.gridX({stroke: "white", strokeWidth: 0.5, strokeOpacity: 0.7})
        ],
      });

      ref.current.append(chart);
      setChart(chart);

      return () => chart.remove();
    }
  }, [data, componentWidth]);

  return (
    <div ref={refParent}>
      {data && (
        <>
          <div ref={ref}></div>
        </>
      )}
    </div>
  );
};

export default ScatterPlot;
