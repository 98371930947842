import Content from "./Content";
import Methodologie from "./Methodologie";
import Data from "./Data";
import biodiversitéImg from "../../../assets/icons/biodiversite.svg";

export const BiodiversitéDemo = {
  id: "biodiversitéDemo",
  label: "Biodiversité Demo",
  cluster: "nature",
  clusterLabel: "Nature",
  description: (
    <div>
      Nonante-cinq pourcent (95%) des types d’habitats naturels sont en <strong>état de conservation défavorable</strong> en Wallonie. Sur les 1322
      espèces de plantes vasculaires présentes en Wallonie, 448 sont considérées comme menacées (34 %). Pour les carabidés, cela concerne 34 % des
      espèces ; pour les papillons de jour, 40 % ; pour les libellules, 26 % ; pour les poissons, 42 % ; pour les amphibiens, 17 % ; pour les
      reptiles, 43 % ; pour les oiseaux, 32 % ; et pour les chauves-souris, 40 %.{" "}
    </div>
  ),
  aleas: [{ name: "Variabilité climatique" }],
  expositions: [{ name: "Aires de répartition des habitats et espèces" }, { name: "Zone de biodiversité", refId: "bioDiversité" }],
  vulnérabilités: [
    { name: "Espèces sensibles aux changements climatiques" },
    { name: "Perte d'habitats" },
    { name: "Capacité migratoire des espèces" },
  ],
  impacts: [{ name: "Distributions et viabilité des habitats et espèces" }, { name: "Perte de biodiversité" }],
  content: <Content />,
  methodologie: <Methodologie />,
  données: <Data />,
  icon: biodiversitéImg,
  maps: ["FichierEcologiquedesEssences", "masqueForestier", "HABITAT/AWAC_BIODIVERSITE_HABITATS"],
  associatedThemes: ["sol", "agriculture"],
};
