import React, { useState } from "react";
import { List, Button, Badge, Tooltip, Input } from "antd";
import { useMaps } from "../../hooks/maps";
import { useMap } from "../../hooks/map";
import { useMapCompare } from "../../hooks/mapCompare";
import { SplitCellsOutlined } from "@ant-design/icons";
import { Highlight } from "react-highlighter-ts";
import { useTheme } from "../../hooks/theme";

export const MapMenu = ({}) => {
  const [map, setMap] = useMap();
  const [mapCompare, setMapCompare] = useMapCompare();
  const [search, setSearch] = useState(null);
  const { data: maps = [] } = useMaps();
  const [theme] = useTheme();

  return (
    <div style={{ padding: 16 }}>
      <Input.Search
        size="large"
        placeholder="Rechercher un carte"
        onChange={(e) =>
          setSearch(
            //@ts-ignore
            e.target.value
              .normalize("NFD")
              .replace(/\p{Diacritic}/gu, "")
              .toLowerCase(),
          )
        }
        allowClear
      />
      <List
        style={{ marginTop: 16 }}
        itemLayout="horizontal"
        dataSource={maps
          //@ts-ignore
          .filter((m) => theme?.maps?.includes(m.id))
          .filter((m) => {
            if (!search || search === "") {
              return true;
            } else {
              //@ts-ignore
              const text = [m.label, m.description]
                .join(" ")
                .normalize("NFD")
                .replace(/\p{Diacritic}/gu, "")
                .toLowerCase();
              //@ts-ignore
              return search.split(" ").every((s) => text.includes(s));
            }
          })}
        bordered
        renderItem={(item) => (
          <List.Item
            //@ts-ignore
            key={item.id}
            actions={[
              <Button
                type="link"
                onClick={() => {
                  //@ts-ignore
                  setMapCompare(mapCompare ? undefined : item.id);
                }}
              >
                <Tooltip title="Comparer cette carte avec celle sélectionnée">
                  {/* @ts-ignore */}
                  <Badge dot={mapCompare && mapCompare.id === item.id}>
                    <SplitCellsOutlined />
                  </Badge>
                </Tooltip>
              </Button>,
            ]}
            //@ts-ignore
            style={{ cursor: "pointer", backgroundColor: map && map.id === item.id ? "#108ee91a" : undefined }}
          >
            <List.Item.Meta
              title={
                //@ts-ignore
                <Highlight ignoreDiacritics={true} search={search}>
                  {/* @ts-ignore */}
                  {item.label}
                </Highlight>
              }
              //@ts-ignore
              description={
                //@ts-ignore
                <Highlight ignoreDiacritics={true} search={search}>
                  {/* @ts-ignore */}
                  {item.description}
                </Highlight>
              }
              //@ts-ignore
              onClick={() => {
                //@ts-ignore
                setMap(item.id);
              }}
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default MapMenu;
