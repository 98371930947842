import Content from "./Content";
import Adaptation from "./Adaptation";
import biodiversitéImg from "../../../assets/icons/biodiversite.svg";

export const BiodiversiteResEco = {
  id: "biodiversité",
  label: "Biodiversité ResEco",
  cluster: "nature",
  clusterLabel: "Nature",
  description: (
    <div>
      L’effondrement de la biodiversité est une crise majeure, mondiale et largement en cours au même titre que celle induite par le changement
      climatique. À l’échelle mondiale, 1 espèce sur 8 est menacée d’extinction tandis que les populations d’espèces communes ont diminué de 20%
      depuis le début du XXe siècle. <a href="https://www.ipbes.net/news/Media-Release-Global-Assessment-Fr">[IPBES]</a>
      La biodiversité joue un rôle multiple dans une perspective d’adaptation au changement climatique :
      <ul>
        <li>
          Elle est un secteur impacté par le changement climatique et à ce titre mérite qu'on mette en place des mesures de gestion lui permettant de
          s'adapter au mieux.
        </li>
        <li>
          Elle est une source de résilience et d'adaptation pour les autres secteurs impactés par le changement climatique (solutions basées sur la
          nature).
        </li>
        <li>
          Elle constitue également une réserve de carbone et contribue ainsi (quand on l’augmente) à l’atténuation du changement climatique, ou à
          l’inverse (quand elle décline) à l’augmentation des teneurs en gaz à effet de serre (GES) dans l’atmosphère.
        </li>
      </ul>
      La thématique « Biodiversité » peut être interprétée en deux « sous-thématiques » étroitement liées : les impacts des changements climatiques
      sur les espèces et leurs habitats, menaçant l’équilibre du système et sa résilience d'une part, et la dégradation des services écosystémiques
      qui en découle, menaçant l’Homme et les activités économiques à l’échelle mondiale d'autre part.
    </div>
  ),
  aleas: [{ name: "modifications des paramètres climatiques" }, { name: "évènements climatiques extrêmes" }],
  expositions: [{ name: "habitats naturels" }, { name: "espèces endémiques" }, { name: "equilibre des cycles et systèmes" }],
  vulnérabilités: [
    { name: "étendue des zones exposées" },
    { name: "statut de protection" },
    { name: "qualité des connexions du réseau écologique" },
    { name: "diversité biologique" },
  ],
  impacts: [
    { name: "dégradation des habitats naturels" },
    { name: "perte de biodiversité (disparition d'espèce, diminution du nombre d'individus)" },
    { name: "modification des interactions entre espèces" },
    { name: "modification des aires de distribution" },
  ],
  facteurs_aggravant: [
    { name: "artificialisation des terres" },
    { name: "fragmentation des habitats" },
    { name: "intensification agricole et sylvicole" },
    { name: "pollutions (engrais, pesticides, lumineuse)" },
    { name: "espèces exotiques envahissantes" },
  ],
  content: <Content />,
  adaptation: <Adaptation />,
  icon: biodiversitéImg,
  maps: ["ReseauNatura2000", "ServicesEcosystemiques", "EspecesExotiquesEnvahissantes", "HABITAT/AWAC_BIODIVERSITE_HABITATS"],
  associatedThemes: ["sol", "agriculture", "eau"],
};
