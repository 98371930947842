//supprimer selon pertinence
import React, { useContext, useEffect, useState } from "react";
import { EventContext, EventHighlight, useFlyTo } from "../../../helpers/Event";
import { useLocation } from "../../../components/Utils/Location";
import Leaves from "../../../components/Charts/Leaves/LeavesComponent";
import ScatterPlotSocial from "../../../components/Charts/ScatterPlotSocial";
import VulnerabilityPlotSocial from "../../../components/Charts/VulnerabilityPlotSocial";
import socialData from "../../../assets/data/social.json";
import _ from "lodash";
import schemaImg from "../../../assets/risqueSchema.png";

const socialDataMap = _(socialData).keyBy("name").value();

export default function Content() {
  //@ts-ignore
  const flyTo = useFlyTo();
  const [event, setEvent] = useContext(EventContext);
  const [location, setLocation] = useLocation();
  const [communeData, setCommuneData] = useState<any>(null);
  const [metric, setMetric] = useState("Statut économique");

  const avgBase = _(socialData[0].indexes)
    .keys()
    .map((metric) => ({ metric, averageBase: 5 })) //d3.median(socialData, (d) => d.indexes[metric]) }))
    .keyBy("metric")
    .mapValues("averageBase")
    .value();

  useEffect(() => {
    if (location && socialDataMap[location]) {
      setCommuneData({
        vulnerability: socialDataMap[location].vulnerability,
        leaves: _(socialDataMap[location].indexes)
          .map((average, metric) => ({
            id: metric,
            average: average,
            averageBase: avgBase[metric],
            label: metric,
            color: "#1676fe",
          }))
          .orderBy("label")
          .value(),
      });
    } else {
      setCommuneData(null);
    }
  }, [location]);

  return (
    <div>
      <p>
        La vulnérabilité sociale de la population traduit le fait que, bien que toute la population soit exposée au changement climatique, toutes les
        personnes ne sont pas égales face aux conséquences des aléas climatiques. Ainsi, certaines personnes ou groupes de population sont plus
        vulnérables en raison de leur âge, de leur état de santé, de leur situation socio- économique, de la qualité de leur réseau social, de
        l’environnement où ils résident, etc. Ces facteurs, d’ordre démographique, socio-économique, social, environnemental et de santé, influencent
        la sensibilité des personnes et leur capacité à faire face en cas de crise.
      </p>
      <p>
        La vulnérabilité de la population est une composante du calcul des risques climatiques. Afin d’estimer le risque lié à un aléa climatique, tel
        que des inondations ou des vagues de chaleur, des informations concernant à la fois l’aléa, la population exposée et la vulnérabilité de
        celle-ci sont nécessaires. Ainsi, l’indicateur de vulnérabilité présenté ici a été utilisé dans le calcul des risques associés à la chaleur et
        aux ilots de chaleur urbains (
        <a href="" target="blank">
          Lien vers les ICU
        </a>
        ).
      </p>
      <div style={{ textAlign: "center" }}>
        <img
          alt="Schéma montrant que le risque est l'intersection des dangers, des vulnérabilités et des expositions"
          src={schemaImg}
          style={{ width: "50%" }}
        />
        <h5 style={{ width: "80%", margin: "auto", color: "grey", marginBottom: 32 }}>
          Le risque est une combinaison des aléas climatiques, de la population exposée et de sa vulnérabilité de celle-ci (Figure adaptée du GIEC,
          2022).
        </h5>
      </div>
      <h4>
        <strong>Ce que montre la carte</strong>
      </h4>
      <p>
        La carte présente l’indicateur de vulnérabilité de la population wallonne aux aléas climatiques. La carte a été obtenue en combinant, dans un
        indicateur composite, 22 facteurs d’ordre démographique, sanitaire, social, socio-économique, environnemental et d’accès aux soins de santé,
        qui ensemble permettent d’approximer la vulnérabilité de la population. Les facteurs utilisés sont exprimés en pourcentage de population.
      </p>
      <div>
        L’indicateur de vulnérabilité sociale de la population ainsi obtenu est disponible :
        <ul>
          <li>Pour les secteurs statistiques de Wallonie, à l’exception de ceux où la population est trop faible (5 personnes).</li>
          <li>Pour la période actuelle. La carte ne montre pas de projection future.</li>
        </ul>
      </div>
      <p>La vulnérabilité sociale est représentée en 5 classes, variant de très faible à très élevée.</p>
      <p>
        À l’échelle de la Wallonie, la carte montre que la vulnérabilité est plus élevée dans les grandes villes et les zones densément urbanisées
        ainsi que les centres villageois contrairement aux zones rurales où la vulnérabilité est en général plus faible.
      </p>
      <h4>
        <strong>Ce que montrent les graphiques</strong>
      </h4>
      <p>
        Contrairement à la carte, qui présente une information détaillée pour chaque secteur statistique (une entité administrative comparable à un
        quartier), les graphiques présentent une information agrégée à l’échelle communale pour chaque commune de Wallonie, sélectionnable sur la
        carte.
      </p>
      <p>
        Les graphiques suivants permettent d’analyser la vulnérabilité d’une commune en fonction de 10 facteurs caractérisant la vulnérabilité de la
        population. Les valeurs des facteurs varient entre 1 et 10. 1 correspond à une situation défavorable (fortement vulnérable) et 10 à une
        situation favorable (peu vulnérable).
      </p>
      <ScatterPlotSocial
        selected={[location]}
        select={(location: string) => {
          flyTo(location, "if search, force going to location");
        }}
        metric={metric}
        setMetric={setMetric}
      />
      {/* <h3 style={{ marginTop: 64, color: "grey", textDecoration: "underline" }}>{location || "Sélectionnez une commune"}</h3> */}
      <h2>Niveau de vulnérabilité de la population {location && !["Région", "Province"].some((d) => location.includes(d)) && `à ${location}`}</h2>
      {location && !["Région", "Province"].some((d) => location.includes(d)) && (
        <div>
          <div style={{ marginBottom: 32 }}>
            <VulnerabilityPlotSocial data={communeData?.vulnerability} />
          </div>
          <h5>Détails des indices</h5>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ maxWidth: 400, marginBottom: 16 }}>
              <Leaves data={communeData?.leaves} selection={metric} onSelect={setMetric} />
            </div>
          </div>
          <div style={{ marginBottom: 64, color: "grey", textAlign: "center" }}>Facteurs de vulnérabilité: 0 → Élevé | 10 → Faible</div>
        </div>
      )}
      {(!location || ["Région", "Province"].some((d) => location.includes(d))) && (
        <div>
          <h5 style={{ marginTop: 100, marginBottom: 150, color: "grey", textAlign: "center" }}> Zoom sur une commune</h5>
        </div>
      )}
      <h4>Rapport méthodologique complet</h4>
      <p>
        Pour plus d’informations sur les données utilisées et la méthode suivie pour obtenir l’indicateur de vulnérabilité, veuillez-vous référer au
        rapport méthodologique <a href="">[Loozen, Y. et al. 2024]</a>
      </p>
    </div>
  );
}
