import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { routes } from "./routes"; // Import the routes
import { ConfigProvider } from "antd";

import "./App.css";
//import "antd/dist/reset.css";
import "./index.css";

export const queryClient = new QueryClient();

const router = createBrowserRouter(routes);

const App = () => (
  <QueryClientProvider client={queryClient}>
    <ConfigProvider
      theme={
        {
          // token: {
          //   // Seed Token
          //   colorPrimary: "#00b96b",
          //   borderRadius: 2,
          //   // Alias Token
          //   colorBgContainer: "#f6ffed",
          // },
        }
      }
    >
      <RouterProvider router={router} />
    </ConfigProvider>

    {window.location.hostname === "localhost" && <ReactQueryDevtools />}
  </QueryClientProvider>
);

export default App;
