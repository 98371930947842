import * as d3 from "d3";
import Chart from "../chart";
import _ from "lodash";

const maxAverage = 8;
const color = (d) => ["#ff0000", "#ff8000", "#ffe242", "#8fd30a", "#39aa15"][Math.floor((d - 1) / 2)];

export default class Leaves extends Chart {
  // First step of the D3 rendering.
  create() {
    this.svg = super.createRoot();
    this.svg
      .select(function () {
        return this.parentNode;
      })
      .style("overflow", "visible");

    this.svg.attr("transform", `translate(${this.props.width / 2}, ${this.props.height / 2})`);

    this.yAxis = this.svg.append("g");

    this.img = this.svg.append("g").attr("class", "img");

    this.main = this.svg.append("g").attr("class", "main");

    this.contextAverage = this.svg.append("g");

    this.subLeaves = this.main.append("g").attr("class", "subLeaves");

    this.leaves = this.main.append("g").attr("class", "leaves");
  }

  // Main D3 rendering, that should be redone when the data updates.
  update(state) {
    this.state = state;
    this.drawAxis(state);
    this.drawChart(state);
  }

  drawAxis({ data }) {
    if (this.yAxis.selectAll("circle").empty()) {
      const innerRadius = 0;
      const outerRadius = Math.min(this.props.width, this.props.height) * 0.5;

      this.yScale = d3.scaleLinear().domain([0, maxAverage]).range([innerRadius, outerRadius]).nice();

      const yAxis = (g) =>
        g
          .attr("text-anchor", "middle")
          .attr("font-family", "sans-serif")
          .attr("font-size", 10)
          .call((g) =>
            g
              .selectAll("g")
              .data(this.yScale.ticks(5).reverse())
              .join("g")
              .attr("fill", "none")
              .call((g) => g.append("circle").attr("stroke", "#000").attr("stroke-opacity", 0.2).attr("r", this.yScale))
              .call((g) =>
                g
                  .append("text")
                  .attr("y", (d) => -this.yScale(d))
                  .attr("dy", "0.35em")
                  .attr("stroke", "#fff")
                  .attr("stroke-width", 5)
                  .text((x, i) => `${x.toFixed(0)}`)
                  .clone(true)
                  .attr("y", (d) => this.yScale(d))
                  .selectAll(function () {
                    return [this, this.previousSibling];
                  })
                  .clone(true)
                  .attr("fill", "currentColor")
                  .attr("stroke", "none"),
              ),
          );

      this.yAxis.call(yAxis);
    }
  }

  drawChart({ data, selection, onSelect, contextAverage }) {
    const self = this;
    if (data) {
      const leafCurve = 0.9;
      const origin = { x: 0, y: 0 };
      const sizeScale = d3
        .scaleLinear()
        .domain([0, maxAverage])
        .range([0, (this.props.width * 0.5) / Math.sqrt(2)]);

      const pistilScale = d3
        .scaleQuantize()
        .domain([0, d3.max(data, (d) => Math.abs(d.avgZScore))])
        .range([0, 1, 2, 3]);

      const leafs = data.map((d, i) => ({
        ...d,
        pistils: Math.ceil(Math.random() * 3), // data.some((d) => d.sumZScore) ? pistilScale(Math.abs(d.avgZScore)) : 0,
        pistilColor: Math.random() >= 0.5 ? "#96d386" : "tomato",
      }));

      const angle = 360 / leafs.length;
      const rotOffset = angle;
      leafs.forEach((d, i) => {
        d.rotation = i * angle;
        d.labelPos = {
          // x: Math.cos(((d.rotation - rotOffset) / 180) * Math.PI) * sizeScale(d.average) * 1.6,
          // y: Math.sin(((d.rotation - rotOffset) / 180) * Math.PI) * sizeScale(d.average) * 1.6,
          x: Math.cos(((d.rotation - rotOffset) / 180) * Math.PI) * sizeScale(8) * 1.6,
          y: Math.sin(((d.rotation - rotOffset) / 180) * Math.PI) * sizeScale(8) * 1.6,
        };
      });

      this.leaves
        .selectAll(".leaf")
        .data(leafs)
        .join(
          (enter) =>
            enter
              .append("g")
              .attr("class", "leaf")
              .call((enter) =>
                enter
                  .append("path")
                  .attr("class", "avg")
                  .attr("fill", (d) => color(d.average)) //d.color)
                  .attr("stroke", "white")
                  .attr("stroke-width", 2)
                  .attr("d", (d, i) => leafPath(origin.x, origin.y, sizeScale(d.average), leafCurve / Math.max(1, leafs.length / 4)))
                  .attr("transform", (d, i) => `rotate(${d.rotation})`)
                  .style("opacity", 0.8),
              )
              .call((enter) =>
                enter
                  .append("path")
                  .attr("class", "ref-avg")
                  .attr("fill", "none")
                  .attr("stroke", "grey")
                  .attr("stroke-width", 1)
                  .attr("stroke-dasharray", 2)
                  .attr("d", (d, i) => leafPath(origin.x, origin.y, sizeScale(d.averageBase), leafCurve / Math.max(1, leafs.length / 4)))
                  .attr("transform", (d) => `rotate(${d.rotation})`)
                  .style("opacity", 0.8),
              )
              .call((enter) =>
                enter
                  .append("text")
                  .attr("class", "label")
                  .attr("fill", "black")
                  .attr("x", (d) => d.labelPos.x)
                  .attr("y", (d) => d.labelPos.y)
                  .attr("text-anchor", "middle")
                  .style("font-weight", "bold")
                  .style("font-size", this.props.width * 0.022)
                  .text((d) => d.label.toUpperCase()),
              )
              .call((enter) => enter.append("g").attr("class", "pistils")),
          (update) => {
            if (true) {
              return update
                .call((update) =>
                  update
                    .select(".avg")
                    .transition()
                    .attr("d", (d) => leafPath(origin.x, origin.y, sizeScale(d.average), leafCurve / Math.max(1, leafs.length / 4)))
                    .attr("fill", (d) => color(d.average)) //d.color)
                    .attr("stroke", (d) => (!selection || selection.length === 0 || selection.includes(d.id) ? "black" : "white"))
                    .attr("transform", (d) => `rotate(${d.rotation}) scale(1)`)
                    .style("opacity", 0.8),
                )
                .call((update) => {
                  update
                    .select("text")
                    .transition()
                    .attr("x", (d) => d.labelPos.x)
                    .attr("y", (d) => d.labelPos.y)
                    .style("opacity", 1)
                    .text((d) => (d.label.toUpperCase() === "ORGANIZATION" ? "ORG" : d.label.toUpperCase()));
                });
            } else {
            }
          },
        )
        .style("cursor", "pointer")
        .on("click", function (event, d) {
          onSelect(d.id, 0);
          const selectedLeaf = d3.select(this);
          if (selection && !selection.includes(d.id)) {
            const radius = self.props.width * 0.1;
            const circle = circlePath(0, 0, radius);

            self.leaves
              .selectAll(".label")
              .filter((l) => l.label !== d.label)
              .transition()
              .duration(500)
              .style("opacity", 0);

            self.leaves
              .selectAll(".avg")
              .filter((l) => l.label !== d.label)
              .transition()
              .duration(1000)
              .attr("transform", (d) => `rotate(${d.rotation}) scale(0)`)
              .style("opacity", 0);

            self.leaves.selectAll(".pistils").attr("transform", (d) => `scale(0)`);

            selectedLeaf.select(".avg").transition().duration(700).delay(500).style("opacity", 1).attrTween("d", pathTween(circle, 2));

            selectedLeaf.select(".label").transition().duration(700).delay(500).attr("x", 0).attr("y", 0);
          } else {
            const leaf = leafPath(origin.x, origin.y, sizeScale(d.average), leafCurve);
            selectedLeaf.select(".avg").transition().duration(500).style("opacity", 0.8).attrTween("d", pathTween(leaf, 2));

            selectedLeaf
              .select(".label")
              .transition()
              .duration(500)
              .attr("x", (l) => l.labelPos.x)
              .attr("y", (l) => l.labelPos.y);

            self.leaves
              .selectAll(".ref-avg")
              .transition()
              .delay(300)
              .duration(1000)
              .style("opacity", 1)
              .attr("transform", (d) => `rotate(${d.rotation}) scale(1)`);

            self.leaves
              .selectAll(".avg")
              .filter((l) => l.label !== d.label)
              .transition()
              .delay(300)
              .duration(1000)
              .attr("transform", (d) => `rotate(${d.rotation}) scale(1)`)
              .style("opacity", 1);

            self.leaves.selectAll(".pistils").attr("transform", (d) => `scale(1)`);

            self.leaves
              .selectAll(".label")
              .filter((l) => l.label !== d.label)
              .transition()
              .delay(900)
              .duration(500)
              .style("opacity", 1);
          }
        });

      const subLeafs = _(data)
        .map((d) => d.subCat)
        .flatMap((subCat) => {
          const angle = Math.min(360 / subCat?.length, 72);
          const rotOffset = angle;
          return _(subCat)
            .orderBy("averageBase")
            .map((d, i) => {
              const pistilScale = d3
                .scaleQuantize()
                .domain([0, d3.max(subCat, (d) => Math.abs(d.avgZScore))])
                .range([0, 1, 2, 3]);

              const rotation = i * angle;
              return {
                ...d,
                rotation,
                labelPos: {
                  x: Math.cos(((rotation - rotOffset) / 180) * Math.PI) * sizeScale(d.average) * 1.6,
                  y: Math.sin(((rotation - rotOffset) / 180) * Math.PI) * sizeScale(d.average) * 1.6,
                },
                path: leafPath(origin.x, origin.y, sizeScale(d.average), leafCurve / Math.max(1, subCat?.length / 4)),
                refPath: leafPath(origin.x, origin.y, sizeScale(d.averageBase), leafCurve / Math.max(1, subCat?.length / 4)),
                pistils: 3, //subCat?.some((d) => d.sumZScore) ? pistilScale(Math.abs(d.avgZScore)) : 0,
                pistilColor: d.avgZScore >= 0 ? "#96d386" : "tomato",
                angle,
              };
            })
            .value();
        })
        .value();

      this.subLeaves
        .selectAll(".leaf")
        .data(subLeafs)
        .join(
          (enter) =>
            enter
              .append("g")
              .attr("class", "leaf")
              .call((enter) =>
                enter
                  .append("path")
                  .attr("class", "avg")
                  .attr("id", (d) => d.label)
                  .attr("fill", (d) => d.color)
                  .attr("stroke", "white")
                  .attr("stroke-width", 2)
                  .attr("d", (d) => d.path)
                  .attr("transform", (d) => `rotate(${d.rotation}) scale(0)`)
                  .style("opacity", 0),
              )
              .call((enter) =>
                enter
                  .append("path")
                  .attr("class", "ref-avg")
                  .attr("fill", "none")
                  .attr("stroke", "grey")
                  .attr("stroke-width", 1)
                  .attr("stroke-dasharray", 2)
                  .attr("d", (d) => d.refPath)
                  .attr("transform", (d) => `rotate(${d.rotation}) scale(0)`)
                  .style("opacity", 0),
              )
              .call((enter) =>
                enter
                  .append("text")
                  .attr("class", "label")
                  .attr("fill", "black")
                  .attr("x", (d) => d.labelPos.x)
                  .attr("y", (d) => d.labelPos.y)
                  .attr("text-anchor", "middle")
                  .style("font-weight", "bold")
                  .text((d) => d.label.toUpperCase())
                  .style("opacity", 0),
              )
              .call((enter) => enter.append("g").attr("class", "pistils")),
          (update) =>
            update
              .call((update) =>
                update
                  .select(".avg")

                  .transition()
                  .attr("d", (d) => d.path)
                  .transition()
                  .duration(300)
                  .delay((d, i) => 500 + i * 100),
              )
              .call((update) =>
                update
                  .select(".ref-avg")
                  .transition()
                  .duration(300)
                  .delay((d, i) => 800 + i * 100),
              )
              .call((update) =>
                update
                  .select("text")
                  .transition()
                  .attr("x", (d) => d.labelPos.x)
                  .attr("y", (d) => d.labelPos.y)
                  .transition()
                  .delay(500)
                  .style("opacity", (d) => (selection && selection.includes(d.cat) ? 1 : 0))
                  .text((d) => d.label.toUpperCase()),
              )
              .call((update) =>
                update
                  .select(".pistils")
                  .selectAll(".pistil")
                  .data((d) =>
                    d3.range(d.pistils).map((i) => ({
                      x: this.props.width * (0.1 + (Math.random() - 0.5) * 0.01),
                      y: -(this.props.width * (0.1 + (Math.random() - 0.5) * 0.01)),
                      color: d.pistilColor,
                      rotation: d.rotation + i * ((d.angle / 110) * 20) - ((d.pistils - 1) * ((d.angle / 110) * 20)) / 2,
                      cat: d.cat,
                    })),
                  )
                  .join(
                    (enter) =>
                      enter
                        .append("g")
                        .attr("class", "pistil")
                        .attr("transform", (d) => `rotate(${d.rotation}) scale(0)`)
                        .style("opacity", (d) => (selection && selection.includes(d.cat) ? 1 : 0))
                        .call((enter) =>
                          enter
                            .append("path")
                            .attr("fill", "none")
                            .attr("stroke", "grey")
                            .attr("stroke-width", 0.7)
                            .attr("d", `M${0},${0}A${0},${0} 0 0,1 ${0},${0}`),
                        )
                        .call((enter) =>
                          enter
                            .append("circle")
                            .attr("fill", (d) => d.color)
                            .attr("cx", 0)
                            .attr("cy", 0)
                            .attr("r", this.props.width * 0.008)
                            .style("stroke", "white"),
                        ),
                    (update) =>
                      update
                        .style("opacity", (d) => (selection && selection.includes(d.cat) ? 1 : 0))
                        .transition()
                        .delay((d, i) => i * 100)
                        .attr("transform", (d) => `rotate(${d.rotation}) scale(${selection && selection.includes(d.cat) ? 1 : 0})`)

                        .call((update) =>
                          update
                            .select("circle")
                            .transition()
                            .duration(300)
                            .delay((d, i) => i * 100)
                            .attr("fill", (d) => d.color)
                            .attr("cx", (d) => d.x)
                            .attr("cy", (d) => d.y),
                        )
                        .call((update) =>
                          update
                            .select("path")
                            .transition()
                            .duration(300)
                            .delay((d, i) => i * 100)
                            .attr("d", (d) => {
                              const distance = Math.sqrt(Math.pow(d.x, 2) + Math.pow(d.y, 2));
                              const dr = distance * 1.5;
                              return `M${0},${0}A${dr},${dr} 0 0,1 ${d.x},${d.y}`;
                            }),
                        ),
                    (exit) =>
                      exit
                        .call((exit) => exit.select("circle").transition().attr("cx", 0).attr("cy", 0).remove())
                        .call((exit) => exit.select("path").transition().attr("d", `M${0},${0}A${0},${0} 0 0,1 ${0},${0}`).remove())
                        .transition()
                        .remove(),
                  )
                  .call((update) =>
                    update.transition().attr("transform", (d) => `rotate(${d.rotation}) scale(${selection && selection.includes(d.cat) ? 1 : 0})`),
                  )
                  .call((update) =>
                    update
                      .select("circle")
                      .transition()
                      .duration(300)
                      .delay((d, i) => 800 + i * 100)
                      .attr("fill", (d) => d.color)
                      .attr("cx", (d) => d.x)
                      .attr("cy", (d) => d.y),
                  )
                  .call((update) =>
                    update
                      .select("path")
                      .transition()
                      .duration(300)
                      .delay((d, i) => 800 + i * 100)
                      .attr("d", (d) => {
                        const distance = Math.sqrt(Math.pow(d.x, 2) + Math.pow(d.y, 2));
                        const dr = distance * 1.5;
                        return `M${0},${0}A${dr},${dr} 0 0,1 ${d.x},${d.y}`;
                      }),
                  ),
              ),
        )
        .style("cursor", "pointer")
        .on("click", function (event, d) {
          onSelect(d.id, 1);
        });
    }
  }
}

function circlePath(cx, cy, r) {
  return (
    "M" + cx + "," + cy + " " + "m" + -r + ", 0 " + "a" + r + "," + r + " 0 1,0 " + r * 2 + ",0 " + "a" + r + "," + r + " 0 1,0 " + -r * 2 + ",0Z"
  );
}

function leafPath(x, y, r, leafCurve) {
  return `M${x}, ${y}
    C${x + leafCurve * r},${y}
    ${x + r},${y - r + leafCurve * r} 
    ${x + r},${y - r} 
    C${x + r - leafCurve * r},${y - r} 
    ${x},${y - leafCurve * r}
    ${x},${y}
  `;
}

function pathTween(d1, precision) {
  return function () {
    var path0 = this,
      path1 = path0.cloneNode(),
      n0 = path0.getTotalLength(),
      n1 = (path1.setAttribute("d", d1), path1).getTotalLength();

    // Uniform sampling of distance based on specified precision.
    var distances = [0],
      i = 0,
      dt = precision / Math.max(n0, n1);
    while ((i += dt) < 1) distances.push(i);
    distances.push(1);

    // Compute point-interpolators at each distance.
    var points = distances.map(function (t) {
      var p0 = path0.getPointAtLength(t * n0),
        p1 = path1.getPointAtLength(t * n1);
      return d3.interpolate([p0.x, p0.y], [p1.x, p1.y]);
    });

    return function (t) {
      return t < 1
        ? "M" +
            points
              .map(function (p) {
                return p(t);
              })
              .join("L")
        : d1;
    };
  };
}
