//supprimer selon pertinence
import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";
import { useLocation } from "../../../components/Utils/Location";
export default function Content() {
  //@ts-ignore
  const [event, setEvent] = useContext(EventContext);
  const [location, setLocation] = useLocation();

  return (
    <div>
      <h4>Aléas</h4>
      <p>
        L’impact du changement climatique sur les logements wallons sera étudié au regard des trois stress climatiques :
        <ul>
          <li>les vagues de chaleur;</li>
          <li>les inondations;</li>
          <li>les feux de forêts.</li>
        </ul>
        Voir respectivement les pages <a href="">Projections</a> et <a href="">Inondations</a> en ce qui concerne les projections climatiques et les
        facteurs aggravants de ces phénomènes.
      </p>

      <h4>Exposition</h4>
      <p>Nous explorons ici l'exposition des bâtiments résidentiels en Wallonie face à ces aléas.</p>
      <h4>Vulnérabilité</h4>
      <p>
        La vulnérabilité de la population est analysée au travers de l’indice de disparité socio-économique à l’échelle des secteurs statistiques. Cet
        indice propose cinq classes de population en fonction du statut socio-économique (calculé sur base de l’origine et de la nationalité, du
        niveau de revenu imposable, du taux de chômage et d’activité, et de la part des ménages bénéficiant d’un revenu de sécurité sociale). La
        classe 1 correspond aux populations les plus démunies, la classe 5 aux populations les plus aisées.
      </p>
      <h4>Impacts</h4>
      <p>
        Les inondations et feux de forêts peuvent engendrer des dégâts importants aux infrastructures et être mortels. Les vagues de chaleur sont
        moins extrêmes mais leur fréquence récurrente peut également avoir des impacts important sur la santé.
      </p>
      <h4>Ce que montrent les cartes</h4>
      <p>
        Sur cette carte vous pouvez explorer les risques qui pèsent sur les logements wallons. Les scénarios futurs de changements climatiques peuvent
        être sélectionnés en haut de la carte. Il vous faut également sélectionner ci-dessous l'aléa que vous souhaitez explorer :
        <ul>
          <li>Vagues de chaleur</li>
          <li>Inondations</li>
          <li>Incendies</li>
        </ul>
        La partie gauche de la carte montre la répartition des logements en Wallonie. La partie droite représente le taux de risque estimé. Les zones
        les plus à risque en Wallonie sont représentées en rouge.
      </p>
      <h4>Rapport méthodologique complet</h4>
      <p>
        <a href="">[Teller, J. et al. 2024]</a>
      </p>
    </div>
  );
}
