import { CompassOutlined, HomeOutlined } from "@ant-design/icons";
import { Result, Spin } from "antd";
import React, { lazy } from "react";
import { useRouteError } from "react-router";
import Layout from "./Layout";
import HomeButton from "./components/UI/HomeButton";
import RetryButton from "./components/UI/RetryButton";
import Loader, { FadeLoaderScreen } from "./components/UI/Loader/Loader";
import Lazy from "./components/UI/Lazy";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import queryString from "query-string";

export const NotFound = () => <Result status="404" title="404" subTitle="Sorry, the page you visited does not exist." extra={<HomeButton />} />;
export const ErrorBoundary = () => {
  const error = useRouteError();
  return <Result status="500" title={error.toString()} subTitle="Sorry, something went wrong" extra={<RetryButton />} />;
};

export const routes = [
  // {
  //   path: "/",
  //   element: (
  //     <QueryParamProvider
  //       adapter={ReactRouter6Adapter}
  //       options={{
  //         searchStringToObject: queryString.parse,
  //         objectToSearchString: queryString.stringify,
  //       }}
  //     >
  //       <Layout />
  //     </QueryParamProvider>
  //   ),
  //   errorElement: <ErrorBoundary />,
  //   children: [
  //     {
  //       id: "explorer",
  //       path: "/",
  //       element: (
  //         <FadeLoaderScreen>
  //           <Lazy component="components/Explorer/Explorer" />
  //         </FadeLoaderScreen>
  //       ),
  //       children: [
  //         {
  //           id: "explorer-theme",
  //           path: "/:theme",
  //           children: [
  //             {
  //               id: "explorer-theme-map",
  //               path: "/:theme/:map",
  //               children: [
  //                 {
  //                   id: "explorer-theme-map-location",
  //                   path: "/:theme/:map/:location",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    path: "/",
    element: (
      <QueryParamProvider
        adapter={ReactRouter6Adapter}
        options={{
          searchStringToObject: queryString.parse,
          objectToSearchString: queryString.stringify,
        }}
      >
        <Layout />
      </QueryParamProvider>
    ),
    errorElement: <ErrorBoundary />,
    children: [
      {
        id: "home",
        path: "/",
        element: (
          <FadeLoaderScreen>
            <Lazy component="components/Acceuil/Acceuil" />
          </FadeLoaderScreen>
        ),
      },
      {
        id: "indicateurs",
        path: "/indicateurs",
        element: (
          <FadeLoaderScreen>
            <Lazy component="components/Indicateurs/Indicateurs" />
          </FadeLoaderScreen>
        ),
      },
      {
        id: "projections",
        path: "/projections",
        element: (
          <FadeLoaderScreen>
            <Lazy component="components/Projections/Projections" />
          </FadeLoaderScreen>
        ),
      },
      {
        id: "cout-inaction",
        path: "/cout-inaction",
        element: (
          <FadeLoaderScreen>
            <Lazy component="components/CoutInaction/CoutInaction" />
          </FadeLoaderScreen>
        ),
      },
    ],
  },
  // {
  //   id: "admin",
  //   path: "/admin",
  //   element: (
  //     <FadeLoaderScreen>
  //       <Lazy component="components/Admin/Admin" />
  //     </FadeLoaderScreen>
  //   ),
  //   errorElement: <ErrorBoundary />,
  //   title: "Admin",
  //   children: [
  //     {
  //       id: "admin-themes",
  //       path: "/admin/themes",
  //       title: "Themes",
  //       element: <Lazy component="components/Admin/Pages/ThemeAdmin" />,
  //       children: [
  //         {
  //           id: "admin-themes-id",
  //           path: "/admin/themes/:themeId",
  //         },
  //       ],
  //     },
  //   ],
  // },
];
