//supprimer selon pertinence
import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";
import { useLocation } from "../../../components/Utils/Location";
export default function Content() {
  //@ts-ignore
  const [event, setEvent] = useContext(EventContext);
  const [location, setLocation] = useLocation();

  return (
    <div>
      <h4>Aléa</h4>
      <p>
        Le changement climatique perturbe le cycle hydrologique, entraînant une augmentation des événements pluvieux extrêmes et des risques
        d'inondations <a href="https://www.ipcc.ch/report/ar6/wg1/downloads/report/IPCC_AR6_WGI_SPM_final.pdf">[IPCC, 2021]</a>. Parallèlement,
        l'élévation des températures et de l'humidité atmosphérique favorise la formation de nuages pluvieux et les inondations sont plus probables
        lors de précipitations intenses et prolongées. Dans la région wallonne, les prévisions futurs indiquent une intensification des
        précipitations, avec une augmentation particulièrement significative selon les projections moyennes, qui suggèrent une augmentation de 40% à
        l'horizon 2085. Les températures moyennes, quant à elle sont attendues d’avoir une hausse de 1,3°C à 2,8°C d'ici 2050 et de 2°C à 4°C d'ici
        2085 <a href="https://awac.be/wp-content/uploads/2021/08/pdf_Note_d_orientation_strategique_cplte.pdf">[AWAC, 2021]</a>.
      </p>
      <h4>Facteurs aggravants</h4>
      <p>
        L'urbanisation, l'utilisation des terres et la compaction du sol par engins lourds entraînent des modifications du couvert végétal et des
        propriétés du sol, diminuant sa capacité d’infiltration et le rend plus susceptible aux inondations.
      </p>
      <h4>Exposition</h4>
      <p>
        Les sols les plus exposés se trouvent généralement dans les zones sujettes aux inondations, telles que les zones basses, les plaines
        inondables et les méandres des cours d'eau. Le taux de sols artificialisés est compris entre 11 et 16% en 2020, avec 7,2% d'imperméabilisation
        du territoire wallon en 2007. Cette situation rend la Wallonie vulnérable aux inondations, que ce soit par le débordement des cours d'eau ou
        le ruissellement. Les communes présentant les taux les plus élevés de sols imperméables pouvant être sensible aux inondations sont situées le
        long de l'axe Mouscron-Mons-Charleroi-Namur-Liège-Verviers, ainsi qu'au nord de cet axe, en particulier en périphérie de Bruxelles.
      </p>
      <h4>Vulnérabilité</h4>
      <p>
        La vulnérabilité des sols est principalement liée au relief de la zone géographique, ainsi qu'aux propriétés des sols, telles que la texture
        et la structure, qui favorisent l'infiltration. Le couvert végétal influence également le ruissellement, de même que la proximité du sol avec
        les cours d'eau et sa position dans les bassins d'alimentation. Certains sols, tels que les zones humides, argileuses et tourbeuses, sont plus
        résistants aux pluies extrêmes et peuvent être des points stratégiques pour favoriser l'infiltration de l'eau dans le sol, limitant ainsi
        l'impact des crues. En raison d'une couverture végétale insuffisante certains sols naturels et agricoles présentent souvent des problèmes
        d'érosion hydrique.
      </p>
      <h4>Impacts</h4>
      <p></p>
      <h4>Ce que montrent les cartes</h4>
      <p>
        Cette carte présente les zones où les propriétés de rétention et d'infiltration des sols peuvent aggraver ou atténuer les inondations, ainsi
        que les zones imperméabilisées. Une partie de la carte présente les scénarios de changement d'aménagement et d'occupation du sol, en prenant
        en considération l'artificialisation des sols pour identifier les zones stratégiques à risque d'être imperméabilisées. La carte hydrologique
        associée vous permet de prendre en compte les risques de ruissellement en aval de l'imperméabilisation d'une parcelle, pour évaluer les
        conséquences potentielles de ces changements d'aménagement sur le cycle hydrologique et les risques d'inondation dans la région.
      </p>
      <h4>Rapport méthodologique complet</h4>
      <p>
        <a href="">[Pirlot, C. et al. 2024]</a>
      </p>
    </div>
  );
}
