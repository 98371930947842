import React, { useState } from "react";
import { Link, Outlet, useMatch } from "react-router-dom";
import { Layout } from "antd";
import { useSize } from "./hooks/size";
import { useResizeDetector } from "react-resize-detector";
import logo from "./assets/wallonia.png";
import { useLocation as useRouterLocation } from "react-router-dom";
import { useQueryParam, StringParam, BooleanParam } from "use-query-params";
import { isMobileMode } from "./components/Indicateurs/SidePanel/SidePanel";
import { useSidePanelStore } from "./hooks/sidePanel";

const { Content, Sider } = Layout;

export const useNoLayout = (): boolean => {
  const [nolayout = false] = useQueryParam("nolayout", BooleanParam);

  const foldMenu = useSidePanelStore((s) => s.setFolded);
  if (nolayout) foldMenu(true);

  return !!nolayout;
};

const MenuButton = ({ text, icon, link }) => {
  const match = useMatch(link);
  return (
    <Link to={link}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          color: match ? "#1890ff" : "black",
        }}
      >
        {React.createElement(icon, { style: { fontSize: 20, marginRight: 10 } })}
        {!isMobileMode() && <span>{text}</span>}
      </div>
    </Link>
  );
};
const AppLayout = () => {
  const noLayout = useNoLayout();
  const { width = 0, ref } = useResizeDetector();
  const ratio = width < 500 ? width / 500 : 1;
  if (noLayout)
    return (
      <Content
        style={{
          height: `${100}vh`,
          position: "relative",
        }}
      >
        <Outlet />
      </Content>
    );
  return (
    <div
      ref={ref}
      style={{
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
      }}
    >
      <Layout.Header
        style={{
          zIndex: 5000,
          width: "100vw",
          height: "64px",
          backgroundColor: "#fff",
          boxShadow: "inset 0 -1px #D3D3D3",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            gap: 30,
          }}
        >
          <img src={logo} alt="logo" height={"45px"} />
          <span style={{ fontSize: "1.2em", fontWeight: "bold" }}>Portail</span>
          {!isMobileMode() && <span style={{ color: "grey" }}>Agence wallonne de l'air et du climat</span>}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifySelf: "flex-end",
            gap: 30,
          }}
        >
          <MenuButton text="Acceuil" icon={require("@ant-design/icons").HomeOutlined} link="/" />
          <MenuButton text="Projections" icon={require("@ant-design/icons").LineChartOutlined} link="/projections" />
          <MenuButton text="Indicateurs" icon={require("@ant-design/icons").CompassOutlined} link="/indicateurs" />
          <MenuButton text="Coût Inaction" icon={require("@ant-design/icons").EuroOutlined} link="/cout-inaction" />
        </div>
      </Layout.Header>
      <Layout
        style={{
          height: `calc(${(1 / ratio) * 100}vh - ${64 * (1 / ratio)}px)`,
          minWidth: width && 500,
          transformOrigin: "top left",
          transform: `scale(${ratio})`,
        }}
      >
        <Layout>
          <Content
            style={{
              height: `calc(${(1 / ratio) * 100}vh - ${64 * (1 / ratio)}px)`,
              overflowY: "scroll",
              position: "relative",
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default AppLayout;
