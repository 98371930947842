import Chart from "./../chart";
import * as d3 from "d3";
import _ from "lodash";
import "./BubbleChart.css";

export default class BubbleChart extends Chart {
  // First step of the D3 rendering.
  create() {
    this.svg = super.createRoot();
    this.main = this.svg.append("g").attr("class", "main");

    this.bubbles = null;
    this.forceStrength = 0.07;
    this.chargePow = 2;

    if (d3.select(".bubbleTooltip").empty()) {
      this.tooltip = d3.select("body").append("div").attr("class", "bubbleTooltip").style("opacity", 0);
    } else {
      d3.select(".bubbleTooltip").select("svg").style("overflow", "visible");
      this.tooltip = d3.select(".bubbleTooltip").style("opacity", 0);
    }
  }

  // Main D3 rendering, that should be redone when the data updates.
  update(state) {
    //if (state.data) {
    this._drawBubble(state.data || [], state.showRemaining);
    //}
  }

  _drawBubble(data, showRemaining) {
    if (!data || !data.top) return null;
    const center = { x: this.props.width / 2, y: this.props.height / 2 };
    const nodes = this.createNodes(data, showRemaining);

    /*details = this.svg.append('text')
      .attr('x', 10)
      .attr('y', 20)
      .attr('font-family', 'helvetica')
      .text('')*/

    this.simulation = d3
      .forceSimulation(nodes)
      .velocityDecay(0.2)
      .force("x", d3.forceX().strength(this.forceStrength).x(center.x))
      .force("y", d3.forceY().strength(this.forceStrength).y(center.y))
      .force(
        "charge",
        d3.forceManyBody().strength((d) => this.charge(d)),
      )
      .on("tick", () => this.ticked());

    this.svg.selectAll(".bubble").remove();
    this.bubbles = this.svg.selectAll(".bubble").data(nodes, function (d) {
      return d.value;
    });

    const self = this;
    const bubblesEnter = this.bubbles
      .enter()
      .append("circle")
      .attr("class", "bubble")
      //.classed("bubble", true)
      .attr("r", 0)
      .attr("cx", (d) => d.x)
      .attr("cy", (d) => d.y)
      .style("fill", (d) => d.color)
      .style("stroke", (d) => d3.rgb(d.color).darker())
      .style("stroke-width", 2)
      .style("stroke-dasharray", (d) => (d.value === "All other answers" ? "5,2" : null))
      .on("mouseenter", function (e, d) {
        if (!self.lassoSelection) {
          const elem = d3.select(this);
          elem.style("stroke", "#3a3a48").style("stroke-width", 2.5);
          self.tooltip.style("opacity", 1);

          self.tooltip.html(`
            <h3>${d.value} </h3>
            Coût: ${d3.format(",")(d.count)}€ <br/>
          `);
          //Proportion: ${d3.format(".1%")(d.proportion)} <br/>
        }
      })
      .on("mousemove", function (d) {
        self.tooltip.style("left", d.pageX + 10 + "px").style("top", d.pageY + 10 + "px");
      })
      .on("mouseleave", function (d) {
        d3.select(this)
          .style("stroke", (d) => d3.rgb(d.color).darker())
          .style("stroke-width", 2);
        self.tooltip.style("opacity", 0);
      })
      .call(
        d3
          .drag()
          .on("start", (e, d) => this.dragstarted(e, d))
          .on("drag", (e, d) => this.dragged(e, d))
          .on("end", (e, d) => this.dragended(e, d)),
      );

    this.bubbles = this.bubbles.merge(bubblesEnter);

    this.bubbles
      .transition()
      .duration(300)
      .style("fill", (d) => d.color)
      .style("stroke", (d) => d3.rgb(d.color).darker())
      .attr("r", function (d) {
        return d.radius;
      });

    this.bubbles.exit().remove();
  }

  createNodes(stats, showRemaining) {
    const size = Math.min(this.props.width, this.props.height);
    const top = stats.top;
    const totalCount = stats.counts.totalCount;
    const sumTop = _(top).map("count").sum();
    const remainingCount = totalCount - sumTop;
    const radiusScale = d3
      .scalePow()
      .exponent(0.5)
      .range([1, size * (0.5 + 0.2)])
      .domain([0, totalCount * Math.PI]);

    const nodes = top.map((d) => {
      return {
        radius: radiusScale(d.count),
        count: +d.count,
        value: d.value !== null ? d.value : "Unknown",
        color: d.color ? d.color : "dodgerblue",
        proportion: d.count / totalCount,
        targetvalue: +d.targetvalue,
        x: Math.random() * this.props.width,
        y: Math.random() * this.props.height,
      };
    });

    if (showRemaining && remainingCount > 0) {
      nodes.push({
        radius: radiusScale(remainingCount),
        count: remainingCount,
        proportion: remainingCount / totalCount,
        value: "All other answers",
        color: "#E0E0E0",
        x: Math.random() * this.props.width,
        y: Math.random() * this.props.height,
      });
    }

    // sort them to prevent occlusion of smaller nodes.
    nodes.sort(function (a, b) {
      return b.count - a.count;
    });

    return nodes;
  }

  charge(d) {
    return -Math.pow(d.radius, this.chargePow) * this.forceStrength;
  }

  ticked() {
    this.bubbles.attr("cx", (d) => d.x).attr("cy", (d) => d.y);
  }

  dragstarted(e, d) {
    if (!e.active) this.simulation.alphaTarget(0.3).restart();
    d.fx = e.x;
    d.fy = e.y;
  }

  dragged(e, d) {
    d.fx = e.x;
    d.fy = e.y;
    this.tooltip.style("opacity", 0);
  }

  dragended(e, d) {
    if (!e.active) this.simulation.alphaTarget(0);
    d.fx = null;
    d.fy = null;
  }

  showDetail(d) {
    d3.select(this).attr("stroke", "black");
    //this.details.text(JSON.stringify(d));
  }

  hideDetail(d) {
    d3.select(this).attr("stroke", d3.rgb("dodgerblue").darker());
    //this.details.text('');
  }
}
