import React, { useContext } from "react";
import { EventContext, EventHighlight } from "../../../helpers/Event";
import { useLocation } from "../../../components/Utils/Location";

export default function Content() {
  //@ts-ignore
  const [event, setEvent] = useContext(EventContext);
  const [location, setLocation] = useLocation();

  return (
    <div>
      <h4>Aléa</h4>
      <p>
        La diversité des espèces vivantes (biodiversité) présentes sur un territoire est intiment liée au climat. Chaque espèce, qu’elle soit animale
        ou végétale, s’épanouit dans des plages plus ou moins étroites des paramètres climatiques : précipitations (quantité et variabilité),
        températures moyennes, maximum, minimum, périodes de sécheresse, de gel. Les changements climatiques qui s’opèrent à l’échelle mondiale
        modifient progressivement le type d’espèces présentes sur nos régions, ce qui n’implique pas nécessairement de perte en biodiversité, mais une
        modification de la composition de celle-ci. Toutefois les changements prévus sont trop rapides par rapport à la capacité de déplacement des
        espèces, notamment des espèces végétales dont les populations se déplacent via la dissémination de leurs graines et la croissance des
        nouvelles générations. De plus les évènements climatiques extrêmes (en particulier les sécheresses) causent des stress qui affaiblissent les
        habitats et espèces et s'ajoutent ainsi aux impacts de chacune des autres menaces précitées.
      </p>
      <h4>Exposition</h4>
      <p>
        Les cibles de ces pressions environnementales sont les espèces endémiques, leurs habitats naturels et les cycles hydrologiques et géochimiques
        naturels dont l’équilibre des écosystèmes dépend (cycle de l’eau, du carbone, de l’azote, …).
      </p>
      <p>
        En belgique, une à deux espèces sur trois sont menacées et la situation est particulièrement préoccupante en Wallonie : nonante-cinq pourcent
        (95%) des types d’habitats naturels sont en état de conservation défavorable. Sur les 1322 espèces de plantes vasculaires présentes, 448 sont
        considérées comme menacées (34 %). Pour les carabidés, cela concerne 34 % des espèces ; pour les papillons de jour, 40 % ; pour les
        libellules, 26 % ; pour les poissons, 42 % ; pour les amphibiens, 17 % ; pour les reptiles, 43 % ; pour les oiseaux, 32 % ; et pour les
        chauves-souris, 40 %. Par ailleurs, la taille de certaines populations a fortement diminué sur les 30 dernières années :
        <ul>
          <li>-30% pour les oiseaux communs</li>
          <li>-57% pour les oiseaux des milieux agricoles</li>
          <li>-28% pour les espèces des forêts et lisières</li>
        </ul>
      </p>
      <h4>Vulnérabilité</h4>
      <p>
        L’ampleur des effets du changement climatique et des pressions qui s’exercent sur la biodiversité dépend largement de la capacité des
        écosystèmes à résister à ces perturbations et à s’adapter à de nouvelles conditions environnementales. En d’autres termes, cela dépend de la
        résilience des écosystèmes. Les éléments qui améliorent la résilience des écosystèmes sont notamment :
        <ul>
          <li>L’étendue des zones exposées </li>
          <li>Le statut des zones exposées et leur protection </li>
          <li>La qualité du réseau écologique et les connexions qui y existent </li>
          <li>La biodiversité</li>
        </ul>
        En effet, plus la diversité des espèces est grande et plus les écosystèmes sont résilients. De même, augmenter les connexions territoriales
        entre les habitats naturels améliore le réseau écologique et permet à la fois de lutter directement contre la fragmentation des habitats et de
        faciliter la migration des espèces lors de modifications des paramètres climatiques.
      </p>
      <h4>Facteurs aggravants</h4>
      <p>
        Les principales pressions qui s’exercent sur les milieux naturels et les espèces sont la perte directe des surfaces d’habitat et leur
        fragmentation, l’imperméabilisation des sols, l’intensification des pratiques agricoles et sylvicoles, la pollution (engrais azotés,
        pesticides, lumineuse) et les espèces exotiques envahissantes. Ces facteurs viennent perturber le bon état écologique des écosystèmes en
        dégradant les éléments de résilience/vulnérabilité de ceux-ci.
      </p>
      <h4>Impacts</h4>
      <p>
        Tous ces éléments mènent à une dégradation des habitats naturels, à la modification des aires de distribution des espèces (animales,
        végétales, sylvicoles) et des interactions entre espèces dues aux changements phénologiques, et à une perte de la biodiversité qui se traduit
        par une diminution du nombre d’individus par espèce mais aussi par une disparition d’espèces endémiques. Les premiers individus exposés par la
        perte de biodiversité, ce sont toutes les espèces autres qu’humaines. Pour eux perte de biodiversité signifie chaque jour un nombre de morts
        qui, s’il s’appliquait à nous les humains, serait qualifié de génocide. Depuis cette perspective, toute estimation de coût est impossible.
        Lorsqu’une espèce disparait, il est impossible de la faire revenir. Même sans disparition de la biodiversité dite "extraordinaire", la
        diminution drastique des populations de la biodiversité dite "ordinaire", est une responsabilité éthique tout aussi intolérable. Ces impacts
        se traduisent par une perte de la résilience du système de façon générale, et de sa capacité d’atténuation des changements climatiques, ce qui
        a des répercutions non négligeables, notamment sur les services écosystémiques rendus par la nature à l’homme.{" "}
        <a href="">[(Voir page Services Ecosystémiques)]</a>
      </p>

      <h4>Ce que montrent les cartes</h4>
      <p>
        Sur ces cartes vous pouvez explorer la vulnérabilité des milieux naturels wallons. Les scénarios futurs de changements climatiques et
        d'occupation du sol peuvent être sélectionnés en haut de la carte.
      </p>
      <p>
        <b>Le taux de réduction de surface d’habitats d’intérêt communautaire.</b>
        C'est-à-dire le taux de perte de biodiversité envisagé pour les milieux naturels actuels selon les scénarios climatiques et d'occupation du
        sol choisis pour le futur. La partie gauche de la carte montre le réseau écologique actuel. La partie droite représente le taux de perte
        future estimé. Les zones les plus à risque de perte de biodiversité en Wallonie sont représentées en rouge.
      </p>
      <p>
        <b>Le taux de réduction de l’aire potentielle d’espèces protégées sélectionnées.</b>
        Le même principe que ci-dessus mais pour des espèces plutôt que des milieux. La partie gauche de la carte montre l'aire de répartition
        actuelle de l'espèce. La partie droite représente l'aire de répartition future'. Les zones d'aires de répartition "perdues" les plus marquées
        en Wallonie sont représentées en rouge.
      </p>
      <p>
        <b>Le taux d’expansion de l’aire potentielle d’espèces exotiques envahissantes.</b>
        Le même principe que ci-dessus mais pour des espèces dont l'augmentation de l'aire de répartition n'est pas souhaitable. La partie gauche de
        la carte montre l'aire de répartition actuelle de l'espèce. La partie droite représente l'aire de répartition future'. Les zones d'aires
        d'expansion les plus marquées de l'aire de répartition en Wallonie sont représentées en rouge.
      </p>

      <h4>Rapport méthodologique complet</h4>
      <p>
        <a href="">[Fanal, A. et al. 2024]</a>
      </p>
    </div>
  );
}
